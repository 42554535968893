import getConfig from 'next/config';

const { environment } = getConfig().publicRuntimeConfig;

export const getDomain = () => {
    switch (environment) {
        case 'development':
            return 'localhost';
        case 'stage':
            return '.qa.sravni-team.ru';
        case 'production':
        default:
            return '.sravni.ru';
    }
};
