import groupBy from 'lodash/groupBy';

import type { IClientMicrocreditListItem } from '@src/@types/clienttypes';

export const formatCardData = (offer: IClientMicrocreditListItem) => {
    const { advertising, organization, detailsCardRates, ...other } = offer;

    // Здесь находится минимальный набор данных для отображения детальной карточки
    return {
        ...other,
        advertising,
        organization: {
            ...organization,
            reviewsSeoStats: {
                reviewsCount: 0,
                negativesCount: 0,
                positivesCount: 0,
                answersCount: 0,
                solutionsCount: 0,
            },
        },
        rates: groupBy(detailsCardRates, 'currency'),
    };
};
