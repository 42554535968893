import has from 'lodash/has';

import { isSpecialPageWithoutQuery } from '@src/utils/routing';

import { getCRMSpecialHeaderText } from './getCRMSpecialHeaderText';

const SPECIAL_PAGE_HEADING = 'Займы онлайн от лучших МФО';

export const getHeadingByRoute = (route: string, query: Record<string, string | string[]>) => {
    const isSpecial = isSpecialPageWithoutQuery(route);
    const isCRMSpecial = isSpecial && has(query, 'landing_type');

    if (isCRMSpecial) {
        return getCRMSpecialHeaderText(query?.landing_type as string);
    }

    if (isSpecial) {
        return {
            heading: SPECIAL_PAGE_HEADING,
            subHeading: '',
        };
    }
};
