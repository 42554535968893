import { useRouter } from 'next/router';
import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getFormatPath } from '@src/helpers/getFormatPath';
import { locationsSelector, useCurrentLocation } from '@src/reducers/locations';
import { prepareSeoUrl } from '@src/utils/routing';

import { RegionsDialog } from './RegionsDialog';

interface IProps {
    isOpen: boolean;
    serviceURL?: string;
    onClose: () => void;
}

export const PageHeaderRegionsDialog: React.FC<IProps> = memo(({ serviceURL: serviceURLBase, isOpen, onClose }) => {
    const { asPath } = useRouter();
    const { alias } = useCurrentLocation();
    const { centers } = useSelector(locationsSelector);
    const serviceURL = prepareSeoUrl(serviceURLBase ?? asPath);
    const formatPath = useMemo(() => getFormatPath(asPath, alias), [alias, asPath]);

    return (
        <RegionsDialog
            isOpen={isOpen}
            formatPath={formatPath}
            serviceURL={serviceURL}
            locations={{ centers }}
            onClose={onClose}
        />
    );
});
