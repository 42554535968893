import add from 'date-fns/add';
import type { MouseEvent } from 'react';
import React, { useState } from 'react';

import { Dialog, Autocomplete } from '@sravni/react-design-system';
import type { SelectOption } from '@sravni/react-design-system/dist/types/components/Select/types/Select.types';
import { Search } from '@sravni/react-icons';
import { useIsMobile } from '@sravni/react-utils';

import type { ILocation } from '@src/@types/locations';
import { getDomain } from '@src/helpers/getDomain';
import { Cookie, CookieKey } from '@src/modules/cookie';
import { locationSuggest } from '@src/services/locations';

import { RegionSection } from '../RegionSection';
import { useSuggest } from '../useSuggest';

import styles from './styles.module.scss';

interface IRegionsDialogProps {
    title?: string;
    isOpen: boolean;
    onClose: () => void;
    serviceURL: string;
    formatPath?: (location: ILocation) => Promise<string> | string;
    locations: {
        regions?: ILocation[];
        centers?: ILocation[];
    };
    getServiceUrl?: (location: ILocation) => Promise<string> | string;
    onSelect?: (location: ILocation) => void;
}

export const RegionsDialog: React.FC<IRegionsDialogProps> = ({
    title = 'Выберите город',
    isOpen,
    onClose,
    locations: { centers = [] },
    getServiceUrl,
    serviceURL,
    formatPath,
    onSelect,
}) => {
    const [value, setValue] = useState<number | string>('');
    const isMobile = useIsMobile();

    const { onChange: onQueryChange, options } = useSuggest<ILocation, string>(locationSuggest);
    const setRegion = async ({
        event,
        location,
        type,
    }: {
        // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
        event?: MouseEvent<HTMLAnchorElement> | void;
        location: ILocation;
        type?: string;
    }) => {
        if (event) {
            event.preventDefault();
        }

        // Here you can send analytics
        if (onSelect) {
            onSelect(location);
        }

        let path = `${serviceURL}vybor-regiona/`;
        const domain = getDomain();

        if (getServiceUrl) {
            path = await getServiceUrl(location);
        }

        if (type === 'centers') {
            onClose();
            Cookie.set(CookieKey._SL_, location.route, { expires: add(new Date(), { years: 1 }), domain });

            if (!getServiceUrl) {
                path = formatPath ? await formatPath(location) : `${serviceURL}${location.alias}`;
            }
        }

        window.location.href = path;
    };
    const onChange = (value: SelectOption['value']) => {
        // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
        const foundLocation: ILocation | void = options.find((option) => option.id === value);
        setValue(value);

        if (foundLocation) {
            setRegion({ location: foundLocation, type: 'centers' });
            onQueryChange(foundLocation.name);
        }
    };

    return (
        <Dialog visible={isOpen} onClose={onClose} fullscreen={isMobile}>
            <Dialog.Header title={title} className={styles.header} />
            <Dialog.Content className={styles.content}>
                <Autocomplete
                    onSearch={(value) => onQueryChange(value)}
                    // @ts-ignore
                    onChange={onChange}
                    label="Введите название города…"
                    options={options.map((option) => ({ label: option.name, value: option.id }))}
                    value={value}
                    icon={<Search />}
                />
                {centers.length > 0 && <RegionSection locations={centers} onRegionSelect={setRegion} type="centers" />}
            </Dialog.Content>
        </Dialog>
    );
};
