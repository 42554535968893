import cn from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import React, { memo, useCallback } from 'react';

import { Icon, Typography } from '@sravni/react-design-system';
import { TailArrowRight } from '@sravni/react-icons';
import { useIsMobile } from '@sravni/react-utils';

import type { AffSub4 } from '@src/@types/microcredits';
import { NoIndex } from '@src/components/NoIndex';
import { PIXELS_TYPES } from '@src/constants/analytics';
import { sendSponsorClickEvent } from '@src/helpers/analyticsEvents';
import { usePixel } from '@src/hooks/usePixel';
import { useShowBottomBannerOnScroll } from '@src/hooks/useShowBottomBannerOnScroll';
import { useBranding, useDesktopBranding, useMobileBranding, usePixelsBranding } from '@src/reducers/branding';

import styles from './styles.module.scss';

interface IProps {
    className?: string;
}

export const PageHeaderSponsor: React.FC<IProps> = memo(({ className }) => {
    const isMobile = useIsMobile();
    const pixels = usePixelsBranding();
    const { scrollRef } = useShowBottomBannerOnScroll();
    const { organization } = useBranding() || {};

    const affSub4: AffSub4 = {
        organizationName: organization?.name ?? '',
        productName: 'Спонсор раздела',
    };

    const pixelsClick = usePixel(pixels?.click, {
        pixelType: PIXELS_TYPES.CLICK,
        affSub4,
    });
    const pixelsDisplay = usePixel(pixels?.display, { affSub4 });
    const pixelsTracking = usePixel(pixels?.tracking, { affSub4 });

    const { calculatorSponsor: desktopSponsor } = useDesktopBranding() || {};
    const { calculatorSponsor: mobileSponsor } = useMobileBranding() || {};

    const sponsor = isMobile ? mobileSponsor : desktopSponsor;

    const buttonColor = sponsor ? sponsor.buttonColor : null;
    const textColor = sponsor ? sponsor.textColor : null;
    const containerColor = sponsor ? sponsor.backgroundColor : null;

    const buttonStyle = React.useMemo(
        () =>
            ({
                '--button-background-color': buttonColor,
            } as React.CSSProperties),
        [buttonColor],
    );

    const textContainerStyle = React.useMemo(
        () =>
            ({
                '--text-color': textColor,
            } as React.CSSProperties),
        [textColor],
    );

    const containerStyle = React.useMemo(
        () =>
            ({
                '--container-color': containerColor,
            } as React.CSSProperties),
        [containerColor],
    );

    const handleSponsorClick = useCallback(() => {
        sendSponsorClickEvent(organization?.name);
    }, [organization?.name]);

    if (!sponsor || _isEmpty(sponsor)) {
        return isMobile ? <div className={styles.scrollMobile} ref={scrollRef} /> : null;
    }

    return (
        <NoIndex className={cn(styles.no_index, className)}>
            <a href={pixelsClick || ''} onClick={handleSponsorClick} target="_blank" rel="noopener noreferrer">
                <div className={styles.container} ref={isMobile ? scrollRef : null} style={containerStyle}>
                    <div className={styles.content}>
                        <div className={styles.logo_container}>
                            <img
                                src={sponsor.logo?.original}
                                alt=""
                                width={sponsor.logo?.original && sponsor?.logoWidth}
                            />
                        </div>
                        <div className={styles.text_container} style={textContainerStyle}>
                            <div className={styles.title}>{sponsor.title}</div>
                            <div className={styles.text}>{sponsor.text}</div>
                            <Typography.Text className={styles.adsLabel}>
                                Реклама {organization?.fullName || organization?.name}
                            </Typography.Text>
                        </div>
                        {pixelsDisplay ? (
                            <img alt="pixelsDisplay" className={styles.pixel} src={pixelsDisplay} />
                        ) : null}
                        {pixelsTracking ? (
                            <img alt="pixelsTracking" className={styles.pixel} src={pixelsTracking} />
                        ) : null}
                        <div
                            className={cn(styles.button, {
                                [styles.buttonColor]: buttonColor,
                                [styles.defaultBackgroundColor]: !buttonColor,
                            })}
                            style={buttonStyle}
                        >
                            <Icon icon={<TailArrowRight className={styles.arrow} />} />
                        </div>
                    </div>
                </div>
            </a>
        </NoIndex>
    );
});
