const pagesWithVisibleDetailsButtonOnMobile = [
    '/zaimy/',
    '/zaimy/bez-otkaza/',
    '/zaimy/s-plokhoj-kreditnoj-istoriej/',
    '/zaimy/bez-protsentov/',
    '/zaimy/top/',
    '/zaimy/pod-pts/',
    '/zaimy/cherez-gosuslugi/',
    '/zaimy/na-kartu-do-zarplaty/',
    '/zaimy/dolgosrochnye/',
    '/zaimy/s-prosrochkami/',
];

const PAGES_WITH_VISIBLE_DETAILS_BUTTON_ON_MOBILE = new Set(pagesWithVisibleDetailsButtonOnMobile);

export const isPageWithVisibleDetailsButtonOnMobile = (path: string) =>
    PAGES_WITH_VISIBLE_DETAILS_BUTTON_ON_MOBILE.has(path);
