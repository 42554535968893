import { useRouter } from 'next/router';
import type { MouseEvent, MutableRefObject, ReactNode } from 'react';
import React, { useLayoutEffect } from 'react';

import { useBoolean } from '@sravni/react-utils';

import type { IClientMicrocreditListItem } from '@src/@types/clienttypes';
import type { CommonCardProps } from '@src/@types/commonCardProps';
import type { IFilters } from '@src/@types/microcredits';
import { isPageWithVisibleDetailsButtonOnMobile } from '@src/constants/pagesWithVisibleDetailsButtonOnMobile';
import { sendOpenCardDetailsInModalEvent } from '@src/helpers/analyticsEvents';

import { CardDetailsModal } from './components';

interface Props extends Omit<CommonCardProps, 'detailsButtonProps'> {
    offer: IClientMicrocreditListItem;
    position: number;
    detailModals: MutableRefObject<Record<string, () => void>>;
    query?: Pick<IFilters, 'term' | 'amount'>;
    className?: string;
    afterCard?: ReactNode;
    renderCard: (props: CommonCardProps) => ReactNode;
}

export const CardWithDetailsModal = ({ renderCard, detailModals, ...cardProps }: Props) => {
    const { asPath } = useRouter();
    const [path] = asPath.split('?');

    const [showModal, setShowModal] = useBoolean(false);

    const { isDuplicate } = cardProps.offer;

    useLayoutEffect(() => {
        if (!detailModals.current[cardProps.offer._id] && !isDuplicate) {
            detailModals.current[cardProps.offer._id] = setShowModal.on;
        }
    }, [detailModals, cardProps.offer._id, setShowModal.on, isDuplicate]);

    const handleClick = (event: MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();

        const { organization, advertising, name } = cardProps.offer;
        const source = `position_${cardProps.position}`;
        const eventLabel = `${organization.name}|${name}|${advertising.offerId}|${source}`;

        sendOpenCardDetailsInModalEvent(eventLabel);
        detailModals.current[cardProps.offer._id]?.();
    };

    return (
        <>
            {renderCard({
                ...cardProps,
                detailsButtonProps: {
                    onClick: handleClick,
                    visibleOnMobile: isPageWithVisibleDetailsButtonOnMobile(path),
                },
                afterCard: !isDuplicate ? (
                    <CardDetailsModal isOpen={showModal} onClose={setShowModal.off} offer={cardProps.offer} />
                ) : null,
            })}
        </>
    );
};
