import { createContext, useContext } from 'react';

import { ListKeys } from '@src/constants/microcredits';

export interface IPageContextConfig {
    listKey: ListKeys;
    source: string;
    sortPanelVisible?: boolean;
    seoLinksVisible?: boolean;
    extraFiltersVisible?: boolean;
    cardTermIsVisible?: boolean;
    withCardProductLink?: boolean;
    withCardClick?: boolean;
    withMonetizationCardClick?: boolean;
    withMonetizationCardLogoClick?: boolean;
    withBanners?: boolean;
    withInvitationBanner?: boolean;
    withEmailBurner?: boolean;
    withSelectionBanner?: boolean;

    isMainPage?: boolean;
}

export const PageContext = createContext<IPageContextConfig>({
    listKey: ListKeys.LIST,
    source: 'search',
    sortPanelVisible: false,
    seoLinksVisible: false,
    extraFiltersVisible: false,
    cardTermIsVisible: false,
    withCardProductLink: false,
    withCardClick: false,
    withMonetizationCardClick: false,
    withMonetizationCardLogoClick: false,
    withBanners: false,
    withInvitationBanner: false,
    withEmailBurner: false,
    withSelectionBanner: false,
});

export const usePageContext = (): IPageContextConfig => useContext(PageContext);
