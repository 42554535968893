import cn from 'classnames';
import React, { memo } from 'react';

import { useIsMobile, useIsTablet } from '@sravni/react-utils';

import style from './style.module.scss';

interface IProps {
    className?: string;
    isOpen: boolean;
    text: string;
    onClick?: () => void;
}

const Toggle: React.FunctionComponent<IProps> = memo((props: IProps) => {
    const { className, isOpen, text, onClick } = props;
    const isMobile = useIsMobile();
    const isTablet = useIsTablet();
    const isViewDesktop = isOpen || !isMobile || isTablet;

    return (
        <div
            className={cn(className, style.wrapper, {
                [style.desktop]: isViewDesktop,
                [style.mobile]: !isViewDesktop,
            })}
            onClick={onClick}
        >
            <span className={style.text}>{text}</span>
        </div>
    );
});

export default Toggle;
