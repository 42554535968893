import debounce from 'lodash/debounce';
import { useEffect, useRef, useState } from 'react';

export function useSuggest<R, T>(fn: (val: T) => Promise<R[]>, debaunce = 300, initialOptions: R[] = []) {
    const [options, setOptions] = useState<R[]>(initialOptions);
    const lastValue = useRef<T>();
    const onChange = useRef<ReturnType<typeof debounce> & ((val: T) => void)>(
        debounce((value: T) => {
            if (lastValue.current === value) {
                return;
            }
            lastValue.current = value;
            fn(value)
                .then((data) => setOptions(data))
                .catch(() => {});
        }, debaunce),
    );
    useEffect(() => {
        const { current } = onChange;
        return () => {
            current!.cancel();
        };
    }, []);

    return {
        onChange: onChange.current!,
        options,
    };
}
