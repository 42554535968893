import cn from 'classnames';
import React, { useCallback, useEffect } from 'react';

import { Icon } from '@sravni/react-design-system';
import type { DialogProps } from '@sravni/react-design-system/dist/types/components/Dialog';
import { Cross } from '@sravni/react-icons';
import { noop, preventBodyScroll, useKeyPress } from '@sravni/react-utils';

import { useOpenModalWithAnimation } from './hooks';
import styles from './styles.module.scss';

interface Props extends DialogProps {
    bodyClassName?: string;
}

export const LocalDialog = (props: Props) => {
    const { visible, className, closable = true, bodyClassName, children, onClose = noop } = props;

    const contentRef = useOpenModalWithAnimation(visible, styles);

    const handlePreventBodyScroll = useCallback(() => {
        if (visible) {
            preventBodyScroll();
        }
    }, [visible]);

    useEffect(() => {
        if (visible) {
            preventBodyScroll(true);
        }

        return handlePreventBodyScroll;
    }, [handlePreventBodyScroll, visible]);

    useKeyPress(
        {
            enabled: !!visible,
            keyMap: {
                Escape: (event) => {
                    event.preventDefault();
                    onClose(event);
                },
            },
        },
        [visible],
    );

    return (
        <div
            role="textbox"
            data-qa="Dialog"
            className={cn(styles.modal, { [styles.activeModal]: visible }, className)}
            onMouseDown={onClose}
            onKeyPress={(e) => e.stopPropagation()}
        >
            <div className={cn(styles.backdrop, { [styles.active]: visible })} />
            <div
                role="textbox"
                tabIndex={0}
                className={cn(styles.modal__content, bodyClassName)}
                onMouseDown={(e) => e.stopPropagation()}
                onKeyPress={(e) => e.stopPropagation()}
                ref={contentRef}
            >
                {closable && <Icon className={styles.close} size={28} icon={<Cross />} onClick={onClose} />}
                {children}
            </div>
        </div>
    );
};
