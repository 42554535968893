import { useEffect, useRef } from 'react';

export const useOpenModalWithAnimation = (visible: boolean, styles: Record<string, string>) => {
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!contentRef.current) {
            return;
        }

        if (visible) {
            contentRef.current.classList.add(styles.active);
        } else {
            contentRef.current.classList.remove(styles.active);
        }
    }, [visible, styles.active]);

    return contentRef;
};
