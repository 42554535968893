import { useMemo } from 'react';

import { AMOUNT_DEFAULT, TERM_DEFAULT } from '@src/constants/fullDeal';
import { useMicrocreditUserDealsState } from '@src/reducers/microcreditUserDeals';

import { getDataAboutMicrocreditDeals } from './helpers';

interface Props {
    initialTerm?: number;
    initialAmount?: number;
    shortTermFormatter?: (shortTerm: string, amount: number) => string | number;
}

export function useMicrocreditDeals({
    initialAmount = AMOUNT_DEFAULT,
    initialTerm = TERM_DEFAULT,
    shortTermFormatter,
}: Props) {
    const microcreditDeals = useMicrocreditUserDealsState();

    return useMemo(
        () => getDataAboutMicrocreditDeals(microcreditDeals, initialAmount, initialTerm, shortTermFormatter),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [microcreditDeals],
    );
}
