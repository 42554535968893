import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import type { IGlobalState } from '@src/reducers';
import { getCreditsMetadata } from '@src/reducers/microcredits';
import { useSeoState } from '@src/reducers/seo';

import { getHeadingText, getSubHeadingText } from './helpers';

interface IParams {
    defaultHeading?: string;
    defaultSubHeading?: string;
}

export const useHeadingTexts = ({ defaultHeading = 'Займы', defaultSubHeading }: IParams | undefined = {}) => {
    const { seo } = useSeoState();
    const currentLocation = useSelector((state: IGlobalState) => state.locations.currentLocation);
    const meta = useSelector(getCreditsMetadata);

    const { asPath, query } = useRouter();

    const headingText = useMemo(() => getHeadingText(seo, asPath, query), [seo, asPath, query]);

    const subHeadingText = useMemo<string | undefined | null>(
        () => getSubHeadingText(seo, meta, currentLocation, asPath, query),
        [seo, meta, currentLocation, asPath, query],
    );

    return {
        headingText: headingText || defaultHeading || '',
        subHeadingText: subHeadingText || defaultSubHeading,
    };
};
