import cn from 'classnames';
import React, { memo } from 'react';

import { Card } from '@sravni/react-design-system';

import styles from './styles.module.scss';

interface IProps {
    itemsCount?: number;
    className?: string;
}

const DEFAULT_COUNT = 16;

const Preloader: React.FC<IProps> = memo(({ itemsCount, className }) => (
    <div className={className}>
        {Array.from({ length: itemsCount || DEFAULT_COUNT }, (_, index) => (
            <div key={index} className={styles.wrapper}>
                <Card size={16} className={styles.root}>
                    <div className={styles.element} />
                    <div className={styles.element} />
                    <div className={cn(styles.element, styles.full)} />
                    <div className={cn(styles.element, styles.full)} />
                    <div className={cn(styles.element, styles.full)} />
                    <div className={cn(styles.element, styles.full)} />
                </Card>
            </div>
        ))}
    </div>
));

export default Preloader;
