import cn from 'classnames';
import React from 'react';

import styles from './styles.module.scss';

const REGION_REGEX = [/{город.где}/, /{город.родительный}/];

interface ILocationHeaderProps {
    className?: string;
    header: string;
    region?: string;
    size?: 'normal' | 'inherit';
    position?: 'left' | 'center';
    onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

export const PageLocationHeader: React.FC<ILocationHeaderProps> = ({ header, className, region, onClick }) => {
    let heading: string | string[] = header;

    const regionRegexIndex = REGION_REGEX.find((item) => item.test(header));

    if (regionRegexIndex) {
        heading = header.split(regionRegexIndex);
    }

    const regionControl = (
        <span className={styles.region}>
            <span className="calculator-list-theme__header-region-name" onClick={onClick}>
                {region}
            </span>
        </span>
    );

    const [regionHeading, regionSubheading] = heading;

    return (
        <div className={cn(styles.page_location, className)}>
            <h1>
                {regionRegexIndex ? (
                    <>
                        <span>{regionHeading}</span>
                        {region && regionControl}
                        {regionSubheading ? <span>{regionSubheading}</span> : null}
                    </>
                ) : (
                    heading
                )}
            </h1>
        </div>
    );
};
