import { DAYS_IN_YEAR } from '@src/constants/dateTime';

const DAYS_IN_MONTH = 30;
const DAYS_IN_WEEK = 7;

export const isoDurationToDays = (isoDuration: string): number => {
    let days = 0;

    const yearsMatch = isoDuration.match(/(\d+)Y/);
    const monthsMatch = isoDuration.match(/(\d+)M/);
    const weekMatch = isoDuration.match(/(\d+)W/);
    const daysMatch = isoDuration.match(/(\d+)D/);

    if (yearsMatch) {
        const years = parseInt(yearsMatch[1], 10);
        days += years * DAYS_IN_YEAR;
    }

    if (monthsMatch) {
        const months = parseInt(monthsMatch[1], 10);
        days += months * DAYS_IN_MONTH;
    }

    if (weekMatch) {
        const weeks = parseInt(weekMatch[1], 10);
        days += weeks * DAYS_IN_WEEK;
    }

    if (daysMatch) {
        days += parseInt(daysMatch[1], 10);
    }

    return days;
};
