import { useRouter } from 'next/router';

import { makeFiltersQueryObject } from '@src/helpers/filters';
import { easyQueryReplace } from '@src/utils/routing';

import { useDebounceCallback } from './useDebounceCallback';

interface IParams<T> {
    newQuery: Partial<T>;
    defaultQuery: Partial<T>;
}

export const useQueryReplace = <T extends {}>() => {
    const router = useRouter();

    return useDebounceCallback(({ newQuery, defaultQuery }: IParams<T>) => {
        const queryParams = makeFiltersQueryObject({
            filters: newQuery,
            defaultFilters: defaultQuery,
        });

        return easyQueryReplace(router, queryParams);
    }, 600);
};
