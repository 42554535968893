import { ListKeys } from '@src/constants/microcredits';

import type { Message } from '../components/MessageBlock';

export const getErrorText = (): Message => ({
    title: 'Упс! Что-то пошло не так...',
    message: 'Произошла ошибка',
});

export const getEmptyProductsTexts = (listKey: ListKeys): Message => {
    switch (listKey) {
        case ListKeys.LIST:
            return {
                title: 'Ничего не найдено :(',
                message:
                    'По вашему запросу нет предложений. Измените сумму или срок, чтобы найти для себя подходящие предложения.',
            };

        case ListKeys.ONLINE:
            return {
                title: 'Ничего не найдено :(',
                message: 'По вашему запросу нет предложений. Попробуйте изменить параметры поиска. Попробуйте еще раз!',
            };

        default:
            return {
                title: 'Ничего не найдено :(',
                message:
                    'По вашему запросу нет предложений. Измените срок или сумму, чтобы найти для себя лучшие предложения. Нужно уменьшить срок или увеличить сумму. Попробуйте еще раз!',
            };
    }
};
