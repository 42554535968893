import { DAYS_IN_YEAR } from '@src/constants/dateTime';

import { getDaysPluralize, getMonthPluralize, getWeeksPluralize, getYearsPluralize } from '../strings';

const DAYS_IN_MONTH = 30;
const DAYS_IN_WEEK = 7;
const ONE_YEAR = 1;

export const formatFullDealOfferTerm = (termInDays: number) => {
    if (termInDays % DAYS_IN_YEAR === 0) {
        return { postfix: getYearsPluralize(ONE_YEAR), displayedTerm: ONE_YEAR };
    }

    if (termInDays % DAYS_IN_MONTH === 0) {
        const termInMonths = termInDays / DAYS_IN_MONTH;

        return { postfix: getMonthPluralize(termInMonths), displayedTerm: termInMonths };
    }

    if (termInDays % DAYS_IN_WEEK === 0) {
        const termInWeeks = termInDays / DAYS_IN_WEEK;

        return { postfix: getWeeksPluralize(termInWeeks), displayedTerm: termInWeeks };
    }

    return { postfix: getDaysPluralize(termInDays as number), displayedTerm: termInDays };
};
