import { DAYS_IN_YEAR, MONTH_PER_YEAR } from '@src/constants/dateTime';
import { TERM_MONTH_MAX, TERM_MONTH_MIN } from '@src/constants/fullDeal';

import { getDaysPluralize, getMonthPluralize, getYearsPluralize } from '../strings';

const DAYS_IN_MONTH = 30;
const ONE_YEAR = 1;

export const formatFullDealFormTerm = (termInDays: number, showMouthsForTerm: boolean) => {
    if (!showMouthsForTerm) {
        return {
            displayedTerm: `${termInDays} ${getDaysPluralize(termInDays)}`,
            termValue: termInDays,
        };
    }

    const termInMonths = Math.min(Math.max(Math.ceil(termInDays / DAYS_IN_MONTH), TERM_MONTH_MIN), TERM_MONTH_MAX);

    if (termInDays === DAYS_IN_YEAR || termInMonths === MONTH_PER_YEAR) {
        return {
            displayedTerm: `${ONE_YEAR} ${getYearsPluralize(ONE_YEAR)}`,
            termValue: ONE_YEAR,
        };
    }

    return {
        displayedTerm: `${termInMonths} ${getMonthPluralize(termInMonths)}`,
        termValue: termInMonths,
    };
};
