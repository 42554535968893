const HEADINGS_BY_LANDING_TYPE: Record<string, { heading: string; subHeading: string }> = {
    '1': {
        heading: 'Займы с высокой вероятностью одобрения',
        subHeading: 'Могут одобрить даже с плохой кредитной историей.',
    },
    '2': {
        heading: 'Займы без дополнительных услуг',
        subHeading:
            'Выбрать займ бывает сложно.\n' +
            'Поэтому мы проверили проверку — сами взяли займы во всех МФО. В этой подборке — займы без дополнительных услуг, чтобы вы не переплачивали за то, что вам не нужно.',
    },
    '3': {
        heading: 'Займы с высокой вероятностью одобрения',
        subHeading: 'Ваша персональная подборка займов готова.',
    },
    '4': {
        heading: 'Новый займ без переплат',
        subHeading: 'Эти МФО дают займы без процентов для новых клиентов.',
    },
};

const DEFAULT_HEADINGS = {
    heading: 'Займы онлайн от лучших МФО',
    subHeading: '',
};

export const getCRMSpecialHeaderText = (landingType: string) =>
    HEADINGS_BY_LANDING_TYPE[landingType] || DEFAULT_HEADINGS;
