import styles from './style.module.scss';

type Props = {
    label: string;
    link: string;
    active: boolean;
};

export const AnchorLinkItem = ({ label, link, active }: Props) => (
    <span className={active ? styles.badgeActive : styles.badge}>{active ? label : <a href={link}>{label}</a>}</span>
);
