import React, { useCallback } from 'react';

export const useRegionDialogToggle = () => {
    const [isOpenedDialog, setRegionDialog] = React.useState<boolean>(false);

    const closeRegionDialog = useCallback(() => setRegionDialog(false), []);

    const toggleRegionDialog = useCallback(() => {
        setRegionDialog((isOpenedDialog) => !isOpenedDialog);
    }, []);

    return { isOpenedDialog, closeRegionDialog, toggleRegionDialog };
};
