import _isArray from 'lodash/isArray';
import _xor from 'lodash/xor';

const HIDDEN_VALUES = [undefined, null, '', 'any'];

interface IProps {
    filters: Record<string, any>;
    defaultFilters: Record<string, any>;
}

export const makeFiltersQueryObject = (props: IProps) => {
    const { filters, defaultFilters } = props;
    const queryFilters: Record<string, any> = {};

    Object.keys(filters).forEach((key) => {
        const value = filters[key];

        if (!HIDDEN_VALUES.includes(value)) {
            const defaultValue = defaultFilters[key];

            if (_isArray(value)) {
                const isNotDefaultValue = _xor(value, defaultValue).length > 0;
                if (isNotDefaultValue) {
                    if (value.length) {
                        queryFilters[key] = value;

                        return;
                    }

                    if (defaultValue?.length) {
                        // set null no have that in query string to parse it later
                        queryFilters[key] = null;
                    }
                }
            } else if (value !== defaultValue) {
                queryFilters[key] = value;
            }
        }
    });

    return queryFilters;
};
